import { Project } from './Components/Project';
import { ProjectContainer } from './Components/Containers';

export const allProjectData = [
    {
        key: 0,
        field:"Computer Vision",
        projects: <>
        <ProjectContainer>
            <Project myTitle = {"Image Segmentation"} action={"view"}/>
            <Project myTitle = {"Connected Components"} action={"view"} />
            <Project myTitle = {"Geo-Feature Computation"} action={"view"} />
            <Project myTitle = {"Corner Detection"} action={"view"} />
            <Project myTitle = {"Edge Detection"} action={"view"} />
            <Project myTitle = {"Histogram Equalization"} action={"view"} />
        </ProjectContainer>
        </>
    },
    {
        key: 1,
        field:"Machine Learning",
        projects: <>
        <ProjectContainer>
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
        </ProjectContainer>
        </>
    },
    {
        key: 2,
        field:"Full Stack Development",
        projects: <>
        <ProjectContainer>
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
        </ProjectContainer>
        </>
    },
    {
        key: 3,
        field:"Back End Development",
        projects: <>
        <ProjectContainer>
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
        </ProjectContainer>
        </>
    },
    {
        key: 4,
        field:"Security",
        projects: <>
        <ProjectContainer>
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
        </ProjectContainer>
        </>
    },
    {
        key: 5,
        field:"Data Science",
        projects: <>
        <ProjectContainer>
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
            <Project myTitle = {"Liscence Plate Bluring"} action={"view"} />
        </ProjectContainer>
        </>
    },
]